import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

import CTA from '../components/CTA'
import Header from '../components/Header'
import { FAQContainer, FAQs } from '../components/FAQs'
import { AboutContact } from '.'
import { Link } from 'gatsby'

export default () => (
  <Layout>
    <Helmet>
      <title>
        High Quality DIY Patio Kits in Brisbane & Sunshine Coast | Just Patios
      </title>
      <meta
        name="description"
        content="Due to popular demand, Just Patios provides DIY roofing kits in the Southeast Queensland region. We use systems that have easy-to-follow instructions & are made with cyclone-rated materials. Call us today for a free quote."
      />
    </Helmet>
    <Header>
      <h1>DIY Patio & Carport Installation Kits</h1>
    </Header>
    <FAQContainer>
      <FAQs>
        <div>
          <p>
            Due to popular demand we also specialise in providing DIY kits to
            the Brisbane and Sunshine Coast areas. It’s easy to see why
            homeowners want to get the most out of DIY kits. Cost-effective and
            easy to install, you won’t need a lot of other items to get started
            with your project.
          </p>

          <p>
            We supply a generic installation manual and material list to help
            make your home building project easier for you. We can supply
            complete Patio and Carport roofing kits offering top quality
            Ausdeck options. These systems are built tough
            to last Queensland's harsh conditions backed with warranty.
          </p>

          <p>
            We can supply a wide range of Ausdeck roofing kits
            giving you the options of flat, pitched, freestanding and attached
            roofs. Whether you want a show-stopping patio or a hardy carport
            with clean lines, we have a kit that allows you to easily achieve
            the effect you want. We’ve handpicked each kit for its quality,
            ensuring each component is fully engineered and tested to Australian
            standards. You can be confident that they will last for years.
          </p>

          <p>
            Just Patios are a trusted provider of{' '}
            <Link to="/patio-roofing-brisbane">
              roofing kits in the Greater Brisbane Region
            </Link>
            &mdash; Northside and Southside &mdash; the Sunshine Coast and Kilcoy
            for residential homes.
          </p>

          <p>
            With years of experience in the trade, no project is too small or
            large. The Types of DIY kits we can deliver include carports, patio
            areas, outdoor entertaining areas, and covered walkways.
          </p>

          <p>
            Just Patios is your reliable supplier of a complete range of
            high-quality DIY roofing kits. If you’re interested in our DIY kits,
            fill out the form below to get your free quote.
          </p>
        </div>
      </FAQs>
    </FAQContainer>

    <AboutContact />
  </Layout>
)
